import { makeStyles } from "@material-ui/styles";

export const useHeaderStyles = makeStyles((theme) => ({
  drawerStyle: {
    zIndex: "1100 !important",
    "& .MuiDrawer-paper": {
      padding: "15px 10px",
    },
  },
  list: {
    width: 260,
  },
  listGroup: {
    padding: ".75rem 0rem",
  },
  labellink: {
    fontSize: 15,
    fontWeight: 500,
    transition: "all 0.3s ease-out 0s",
    color: "rgb(22, 28, 45) !important",
  },
}));
