import React from 'react'

function Footer() {
    return (
        <div>
            <div className="footer-section pd-top pd-bottom dark-mode-texts bg-blackish-blue">
                <div className="container">
                    <div className="justify-content-center row text-white">
                    Copyright © 2016 , Inc. All rights reserved. 
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
