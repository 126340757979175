import { Hidden, IconButton, Drawer } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHeaderStyles } from "./HeaderStyle";

const Header = (props) => {
  const classes = useHeaderStyles();

  const [statedrawer, setStateDrawer] = useState(false);

  const handeldraweropen = () => {
    if (statedrawer === false) {
      setStateDrawer(true);
    } else {
      setStateDrawer(false);
    }
  };

  const toggleDrawer2 = () => {
    setStateDrawer(false);
  };

  return (
    <div>
      <header className="Header__SiteHeader-sc-1tymobh-0 fDpXlx site-header  site-header-Absolute py-0 sticky-header site-header--menu-left">
        <div className="container">
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg px-0">
            <div className="brand-logo">
              <Link className="d-block " to="/">
                <img src="_next/static/doctor/logo2.png" alt="doctor" />
              </Link>
            </div>
            <div className="collapse navbar-collapse">
              <div className="mg-leftmenu">
                <ul className="navbar-nav main-menu d-none d-lg-flex">
                  <li className="nav-item dropdown">
                    <Link className="nav-link " to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link " to="/about">
                      About us
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link " to="/services">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link " to="/caretraining">
                      Care Traning
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link " to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link">
                      <i className="icon icon-phone-2"></i>Call:404-343-6781
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* <button className="Header__ToggleButton-sc-1tymobh-1 giNJFO navbar-toggler btn-close-off-canvas ml-3 " type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false" aria-label="Toggle navigation"><i className="icon icon-menu-34 icon-burger d-block" /></button> */}
            <Hidden lgUp>
              <IconButton color="inherit" onClick={handeldraweropen}>
                <button
                  className="Header__ToggleButton-sc-1tymobh-1 giNJFO navbar-toggler btn-close-off-canvas ml-3 "
                  type="button"
                  data-toggle="collapse"
                  data-target="#mobile-menu"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="icon icon-menu-34 icon-burger d-block" />
                </button>
              </IconButton>

              <Drawer
                anchor={"right"}
                className={classes.drawerStyle}
                onClose={toggleDrawer2}
                open={statedrawer}
              >
                <div>
                  <div className={classes.list} role="presentation">
                    <div className="container">
                      <div className="Offcanvas__LogoContainer-sc-1sp39vw-2 bxEUHe my-3"></div>
                      <div className="pt-4">
                        <div className="NestedMenu__NestedMenuContainer-sc-1elrylq-0 iusOaK">
                          <div
                            className="list-group list-group-flush"
                            onClick={toggleDrawer2}
                          >
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <Link className={classes.labellink} to="/">
                                Home
                              </Link>
                            </div>
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <Link className={classes.labellink} to="/about">
                                About us
                              </Link>
                            </div>
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <Link
                                className={classes.labellink}
                                to="/services"
                              >
                                Services
                              </Link>
                            </div>
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <Link
                                className={classes.labellink}
                                to="/caretraining"
                              >
                                Care Traning
                              </Link>
                            </div>
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <Link className={classes.labellink} to="/contact">
                                Contact Us
                              </Link>
                            </div>
                            <div
                              className={`${classes.listGroup} NestedMenu___StyledListGroupItem-sc-1elrylq-1 gojStG d-flex align-items-center justify-content-between list-group-item`}
                            >
                              <span className={classes.labellink}>
                                <i className="icon icon-phone-2"></i>
                                Call:404-343-6781
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Hidden>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
