import React from "react";
import { Link } from "react-router-dom";
import Slider from '../components/Slider';

function Home() {
  return (
    <div>
      {/* <div className="bg-default-8 pt-slide" id="hero-area-animation">
        <div id="animation-area-1">
          <div className="align-items-center justify-content-center row">
            <div className="order-lg-1 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="hero-content section-title text-center text-lg-left">
                <div className="hero-img image-group-p12 position-relative mb-9 mb-sm-15 mb-lg-0">
                  <img
                    className="w-100"
                    src="_next/static/doctor/hede.jpg"
                    alt="doctor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Slider />

      <div className="alert-section py-9 bg-lightblue dark-mode-texts ">
        <div className="container">
          <div className=" row">
            <div className="col-xl-7 col-lg-8 col-md-9">
              <div className="alert-content d-flex flex-column flex-lg-row align-items-center text-center justify-content-lg-center">
                <span className="btn-badge rounded-pill gr-text-12 text-uppercase font-weight-bold text-blue py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">
                  <img src="_next/static/doctor/care-img.jpg" alt="doctor" />
                </span>
                <span className="alert-text gr-text-9 text-white">
                  <b>For in Home Care</b>
                  <br />
                  "We Are Here To Serve You Around The Clock"
                </span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-3">
              <div className="read-btn-05">
                <Link to="/services">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-section pt-14 pt-lg-21 pb-7 bg-default-6">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="section-title text-center mb-13 mb-lg-21">
                <h2 className="title gr-text-4 mb-6">In-Home Care</h2>
                <p className="gr-text-9 mb-0">
                  24 Hours Home Care is a premier provider for In-Home Care, Our
                  team provides high quality care to people throughout the metro
                  atlanta area and we are passionate about serving you and your
                  needs. If you are looking to bring care into your home, our
                  team of certified caregivers are trained in all aspects of
                  senior care from Alzheimer’s and dementia care, end-of-life
                  and hospice care, to companionship and personal assistance. We
                  provide services throughout Atlanta, GA and surrounding areas.
                </p>
              </div>
            </div>
          </div>
          <div className="align-items-center justify-content-center row">
            <div className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-0 px-xl-8 col-lg-4 col-md-6">
              <div className="feature-widget text-center care-box">
                <div className="widget-icon rounded-15 mx-auto mb-9 shadow-blue care-img03">
                  <img src="_next/static/doctor/care02-img.jpg" alt="true" />
                </div>
                <div className="widget-text">
                  <h3 className="title gr-text-font mb-7">
                    We Make Home Care Affordable
                  </h3>
                  <p className="gr-text-11 mb-0">
                    At 24 Hours Home Care, we believe in providing a high
                    quality of care at a rate that won't break the bank. Unlike
                    many other providers, we charge an affordable rate for
                    everything including personal care, companion visits,
                    couples care, and more!
                  </p>
                </div>
                <div className="read-btn-04">
                  <Link to="/services">Read More</Link>
                </div>
              </div>
            </div>
            <div className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-0 px-xl-8 col-lg-4 col-md-6">
              <div className="feature-widget text-center care-box">
                <div className="widget-icon rounded-15 mx-auto mb-9 shadow-blue care-img03">
                  <img src="_next/static/doctor/consultant.jpg" alt="true" />
                </div>
                <div className="widget-text">
                  <h3 className="title gr-text-font mb-7">FREE Consultation</h3>
                  <p className="gr-text-11 mb-0">
                    If you would like to meet a representative to discuss how we
                    can assist you or your loved one, we are happy to offer a
                    FREE consultation in your home. Our representative will help
                    make your search more smooth and informative
                  </p>
                </div>
                <div className="read-btn-04">
                  <Link to="/services">Read More</Link>
                </div>
              </div>
            </div>
            <div className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-0 px-xl-8 col-lg-4 col-md-6">
              <div className="feature-widget text-center care-box">
                <div className="widget-icon rounded-15 mx-auto mb-9 shadow-blue care-img03">
                  <img src="_next/static/doctor/free-call.jpg" alt="true" />
                </div>
                <div className="widget-text">
                  <h3 className="title gr-text-font mb-7">AVAILABLE 24/7</h3>
                  <p className="gr-text-11 mb-0">
                    A member of our management team is available 24 hours/day
                    and we are available to serve your needs at a moment’s
                    notice. Our team of care professionals is ready to fill any
                    and all of your needs at a moment’s notice.
                  </p>
                </div>
                <div className="read-btn-04">
                  <Link to="/services">Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
