import React, { useState } from "react";

const Caretraining = () => {
  const [hidecontent, setHideContent] = useState(true);
  const [dummylogin, setDummyLogin] = useState(false);
  const handelClickLogin = () => {
    setHideContent(false);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
  };
  const handelGuest = (e) => {
    e.preventDefault();
    setHideContent(true);
    setDummyLogin(true);
  };

  return (
    <div>
      {hidecontent === true ? (
        <div>
          <div className="alert-section pd-header mg-top113 bg-darkgray dark-mode-texts ">
            <div className="pd-slide65">
              <div className="divLogin">
                <span className="spanpointer" onClick={handelClickLogin}>
                  {dummylogin
                    ? "You are currently using guest access (Log in)"
                    : "Login"}
                </span>
              </div>
            </div>
          </div>
          <div className="Video___StyledDiv-sc-3hbr24-0 hExmrV video-section dark-mode-texts ">
            <img
              className="cover-slide"
              src="_next/static/doctor/image_3.jpg"
              alt="doctor"
            />
          </div>
          <div className="alert-section py-9 bg-lightblue dark-mode-texts ">
            <div className="pd-slide65">
              <div className="col-xl-7 col-lg-8 col-md-9">
                <div className="alert-content d-flex flex-column flex-lg-row align-items-center text-center ">
                  <span className="btn-badge rounded-pill gr-text-12 text-uppercase font-weight-bold text-blue py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">
                    <h3>AVAILABLE COURSES</h3>
                  </span>
                </div>
              </div>

              <div className="justify-content-center row">
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">Course 1: Alzheimer's Disease</h6>
                </div>
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">Course 2: Behavior Disorders</h6>
                </div>
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">Course 3: Birth Defects</h6>
                </div>
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">Course 4: Blindness</h6>
                </div>
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">
                    Course 5: Developmental Disorders
                  </h6>
                </div>
                <div className="mb-9 mb-lg-0 col-lg-2 col-sm-6 col-10">
                  <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                    <img
                      src="_next/static/doctor/no-image.jpg"
                      className="card-img-top rounded-top-10"
                      alt="true"
                    />
                  </div>
                  <h6 className="center-text">Course 6: Diabetes</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-slide65 mg-67">
            <div className="service-section pt-12">
              <div className="row contact_text">
                <div class="col-md-12">  
                  <h2>SEARCH YOUR COURSES</h2>
                </div>
              </div>
            </div>

            <form id="coursesearch">
              <fieldset className="coursesearchbox invisiblefieldset">
                <label>Search courses: </label>
                <input
                  type="text"
                  id="shortsearchbox"
                  size="12"
                  name="search"
                  placeholder="Type and press Search"
                  //   value=""
                />
                <input type="submit" value="Search" />
              </fieldset>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className="login-section pd-header2 mg-top113 bg-darkgray dark-mode-texts ">
            <div className="card">
              <div className="row">
                <div className="col-md-5 offset-md-1">
                  <form className="m-t-1" id="login" onSubmit={handelSubmit}>
                    <label className="sr-onl">Username</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="form-control"
                      placeholder="Username"
                    />
                    <label htmlFor="password" className="sr-onl">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <div className="rememberpass m-t-1">
                      <input
                        type="checkbox"
                        name="rememberusername"
                        id="rememberusername"
                        defaultValue={1}
                      />
                      <label htmlFor="rememberusername">
                        Remember username
                      </label>
                    </div>
                    <button
                      className="btn btn-primary btn-block m-t-1"
                      id="loginbtn"
                    >
                      Log in
                    </button>
                  </form>
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className="forgetpass m-t-1">
                    <p>Forgotten your username or password?</p>
                  </div>
                  <div class="m-t-1">
                    <p>Cookies must be enabled in your browser</p>
                  </div>
                  <div class="m-t-2">
                    <p>Some courses may allow guest access</p>
                    <form id="guestlogin" onSubmit={handelGuest}>
                      <input type="hidden" name="username" value="guest" />
                      <input type="hidden" name="password" value="guest" />
                      <button class="btn btn-secondary btn-sec btn-block">
                        Log in as a guest
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer id="site-footer">
        <div>
          <div className="footer-main">
            <div className="bgtrans-overlay">
              <div className="container footer-main-wrap">
                <div className="row 1">
                  <div className="col-md-6">
                    <h6 className="footer-main-wrap">About us</h6>
                    <div className="footer-links">
                      24 Hours Home Care is a premier provider for In-Home Care,
                      Our team provides high quality care to people throughout the
                      metro Atlanta area and we are passionate about serving you
                      and your needs. If you are looking to bring care into your
                      home, our team of certified caregivers are trained in all
                      aspects of senior care from Alzheimer's and dementia care,
                      end-of-life and hospice care, to companionship and personal
                      assistance.Start Learning Now
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6 className="footer-main-wrap"> CONTACT US </h6>
                    <div className="footer-address-block footer-main-wrap ">
                      <div className="footer-address">
                        <p className="footer-main-wrap">
                          <i className="fa fa-map-marker footer-main-wrap" />
                          LLC 5405 Memorial Drive, Suite A 103 Stone Mountain, GA
                          30083
                        </p>
                      </div>
                      <div className="footer-phone">
                        <p className="footer-main-wrap footer-address">
                          <i className="fa fa-phone-square footer-main-wrap" />
                          Phone: 404-343-6781
                        </p>
                      </div>
                      <div className="footer-email">
                        <p className="footer-main-wrap footer-address">
                          <i className="fa fa-envelope footer-main-wrap" />
                          E-mail:
                          <a
                            className="footer-main-wrap"
                            href="mailto:Anab@24hrshomecare.Net"
                          >
                            Anab@24hrshomecare.Net
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Caretraining;
