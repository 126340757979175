import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./pages/About";
import Caretraining from "./pages/Caretraining";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Services from "./pages/Services";

function App() {
  return (
    <div className="site-wrapper overflow-hidden">
      <BrowserRouter basename="/">
        {/* <BrowserRouter basename="/"> */}
        <Header />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/caretraining" component={Caretraining} />
          <Route exact path="/contact" component={Contact} />
        </Switch>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
