import React from 'react'

function Contact() {
    return (
        <div>
            <div className="CTA___StyledDiv-sc-1fjdmfn-0 itNLyf padding-176 cta-section parallax-section-410 bg-parallax-image">
                <div className="container servic_bot">
                    <h2>Contact</h2>
                    <p>Home / Contact</p>
                </div>
            </div>
            <div className="about-content pt-13 pb-13 pb-lg-25">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact_text">
                                <h2>we service the following counties</h2>
                                <ul>
                                    <li>Dekalb</li>
                                    <li>Fulton</li>
                                    <li>Cobb</li>
                                    <li>Gwinnett</li>
                                    <li>Cherokee</li>
                                </ul>
                                <ul>
                                    <li>Douglas</li>
                                    <li>Paulding</li>
                                    <li>Fayette</li>
                                    <li>Clayton</li>
                                    <li>Henry</li>
                                </ul>
                            </div>
                            <div className="contact_text1">
                                <h2 className="mb-0">Contact Us</h2>
                                <p className="gr-text-contact mb-1">
                                24 Hours Home Care, LLC 5405 Memorial Drive,<br/>
                                Suite A 103 Stone Mountain, GA 30083
                                </p>
                            <p className="gr-text-contact mb-1">
                            404-343-6781 Fax: 404-592-6262
                            </p>
                            <p className="gr-text-contact mb-1">
                            anab@24hrshomecare.net
                            </p>
                            </div>
                        </div>
                        <div className="mb-7 mb-lg-0 col-lg-6">
                        {/* <iframe 
                            width="300" 
                            height="170" 
                            frameborder="0" 
                            scrolling="no" 
                            marginheight="0" 
                            marginwidth="0" 
                            src="https://maps.google.com/maps?q=33.798573,-84.218685&z=15&output=embed"
                        >
                        </iframe>
                        <br />
                        <small>
                        <a 
                            href="https://maps.google.com/maps?q='+data.lat+','+data.lon+'&hl=es;z=14&amp;output=embed" 
                            style="color:#0000FF;text-align:left" 
                            target="_blank"
                        >
                            See map bigger
                        </a>
                        </small> */}
                            <iframe className="map-h-w" allowfullscreen="" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5405%20Memorial%20Drive,%20Stone%20Mountain,%20GA%2030083+(24%20Hours%20Home%20Care%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"  frameborder="0"></iframe>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
