import { makeStyles } from '@material-ui/core/styles';

// const theme = useTheme();
export const useSliderStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
    marginTop: 110,
    position: 'relative'
  },
  // header: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   height: 50,
  //   paddingLeft: theme.spacing(4),
  //   backgroundColor: theme.palette.background.default,
  // },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height:650,
    transform: 'scaleX(1.5)',
    [theme.breakpoints.down('sm')]: {
      height:400,
      width:'165%',
      transform:'scaleX(1.2)'
    },
    [theme.breakpoints.down('xs')]: {
      height:200,
      width:'165%',
      transform:'scaleX(1.2)'
    }
  },
  imgDiv:{
    overflow: 'hidden',
  },
  bannerFormDiv:{
    position: 'absolute',
    top: '100px',
    width: '370px',
    right: '10%',
    border: '3px solid #60b6db',
    borderRadius: '13px',
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      top: 'auto',
      width: 'auto',
      right: 'auto',
      margin: '20px 10px',
    }
  },
  headerText: {
    background: '#60b6db',
    padding: '5px',
    borderRadius: '9px 9px 0 0',    
    '& h2':{
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'center',
      color: '#ffffff',
    }
  },
  bannerForm:{
    padding: '20px',
    background: '#f7f7f7',
    borderRadius: '0px 0px 12px 12px',
    '& .MuiTextField-root':{
      marginBottom:15,
    },
    '& .MuiInputBase-root':{
      borderRadius: 0,
      background: '#ffffff'
    },
    '& .MuiInputBase-input':{
      background: '#ffffff',
      height: '38px',
      paddingLeft: '12px'
    },
    '& .MuiButton-root':{
      width: '100%',
      fontSize: '38px',
      textTransform: 'initial',
      fontWeight: 600,
      padding: 0,
      background: '#81b000',
      borderRadius: '15px',
      border: '1px solid #81b000',
      color: '#fff',
    },
    '& .MuiButton-root:hover':{
      background : '#ffffff',
      color: '#81b000'
    }
  },
  arrowImg:{
    position: 'absolute',
    left: '-39px',
    zIndex: '9',
    bottom: '30px'
  }
}));
