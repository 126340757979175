import React from 'react'

function Services() {
    return (
        <div>
            <div className="CTA___StyledDiv-sc-1fjdmfn-0 itNLyf padding-176 cta-section parallax-section-410 bg-parallax-image">
                <div className="container servic_bot">
                    <h2>Services</h2>
                    <p>Home / Services</p>
                </div>
            </div>
            <div className="service-section pt-12 pb-7 pb-lg-25 pt-lg-19" >
                <div className="container">
                    <div className="justify-content-center row">
                        <div className="mb-9 mb-lg-0 col-lg-4 col-sm-6 col-10">
                            <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                                <img src="_next/static/doctor/servic1.png" className="card-img-top rounded-top-10" alt="..." />
                                <div className="card-body bg-white rounded-bottom-10 text_servi px-7 py-6">
                                    <h3>Companionship and Home Helper</h3>
                                    <ul>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Meal Preparation</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Medication Reminders</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Accompany to Doctor visits</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Grocery shopping and errands</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Laundry</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Socializing and activities</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Light Housekeeping/Home Management</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="mb-9 mb-lg-0 col-lg-4 col-sm-6 col-10">
                            <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                                <img src="_next/static/doctor/servic2.png" className="card-img-top rounded-top-10" alt="..." />
                                <div className="card-body bg-white rounded-bottom-10 text_servi px-7 py-6">
                                    <h3>Companionship and Home Helper</h3>
                                    <ul>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Bathing</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Dressing</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Incontinence</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Mobility Assistance (Help Getting Around)</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />Managing Behaviors</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />SAssisting with Daily living activitiess</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />encouraging engagement</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />safety</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="mb-9 mb-lg-0 col-lg-4 col-sm-6 col-10">
                            <div className="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
                                <img src="_next/static/doctor/servic3.png" className="card-img-top rounded-top-10" alt="..." />
                                <div className="card-body bg-white rounded-bottom-10 text_servi px-7 py-6">
                                    <h3>Companionship and Home Helper</h3>
                                    <ul>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />nursing care in your home (RN, LPN)</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />wound care</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />medication setup and administration</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />other skilled care as prescribed by client's physician</li>
                                        <li><i className="icon icon-tail-right icon-pd-5 font-weight-bold" />post-surgical care</li>
                                        <li></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services
