import React from "react";

function About() {
  return (
    <div>
      <div className="CTA___StyledDiv-sc-1fjdmfn-0 itNLyf padding-176 cta-section parallax-section-410 bg-parallax-image">
        <div className="container servic_bot">
          <h2>About Us</h2>
          <p>Home / About Us</p>
        </div>
      </div>
      <div className="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="pr-xl-13">
                <h2 className="gr-text-3 mb-0">About Us</h2>
                <p className="gr-text-about mb-1">
                  24 Hours Home Care is a premier provider for In-Home Care, Our
                  team provides high quality care to people throughout the metro
                  Atlanta area and we are passionate about serving you and your
                  needs. If you are looking to bring care into your home, our
                  team of certified caregivers are trained in all aspects of
                  senior care from Alzheimer's and dementia care, end-of-life
                  and hospice care, to companionship and personal assistance.
                </p>
                <p className="gr-text-about mb-1">
                  We provide services throughout Atlanta, GA and surrounding
                  areas.
                </p>
                <p className="gr-text-about mb-1">
                  We Make Home Care Made Affordable
                </p>
                <p className="gr-text-about mb-0">
                  24 Hours Home Care we believe in providing high quality of
                  care at a rate that won't break the bank. Unlike many other
                  providers, we charge an affordable rate for everything
                  including personal care, memory care, couples care, and more!
                </p>
              </div>
            </div>
            <div className="mb-7 mb-lg-0 col-lg-5">
              <img src="_next/static/doctor/aboutim.png" alt="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
