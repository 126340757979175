import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import { Typography, TextField, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useSliderStyles } from "./SliderStyle";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const tutorialSteps = [
  {
    id: 1,
    label: '24 Hours Home Care',
    imgPath:
      './banner/banner2.jpg',
  },
  {
    id: 2,
    label: '24 Hours Home Care',
    imgPath:
      './banner/banner3.jpg',
  },
  {
    id: 3,
    label: '24 Hours Home Care',
    imgPath:
      './banner/banner4.jpg',
  } 
];

function Slider() {

  const classes = useSliderStyles();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      {/* <Paper square elevation={0} className={classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.key } className={classes.imgDiv}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      /> */}
      <div className={classes.bannerFormDiv}>
        <div className={classes.headerText}>
          <h2>Ask A Question <br /> 
          Call us today to understand <br />
          how friendly homecare can make a difference.</h2>
        </div>
        <form className={classes.bannerForm} noValidate autoComplete="off">
          <TextField fullWidth={true} id="outlined-basic" placeholder="Enter your Name" variant="outlined" />
          <TextField fullWidth={true} id="outlined-basic" placeholder="Enter your Phone number" variant="outlined" />
          <TextField fullWidth={true} id="outlined-basic" placeholder="Enter your Email" variant="outlined" />
          <TextField fullWidth={true} id="outlined-basic" placeholder="Appointment Date" variant="outlined" />
          <img src="./banner/red-btn.png" className={classes.arrowImg}/> 
          <Button variant="outlined">Call us Today</Button>
        </form>
      </div>
    </div>
  );
}

export default Slider;

